import React from "react"
import Img from "gatsby-image"

const Hero = ({ children, image }) => {
    return (
        <div className="myhero">
            <div className="hero-image">
                <Img fluid={image} style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%"
                }}
                    alt="Bar image"
                />
            </div>

            <div className="container hero-container">
                {children}
            </div>
        </div>
    )
}

export default Hero
