import React from "react"
import { Link as ScrollLink, Element } from 'react-scroll';

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import logoWhite from "../images/logo_white.svg";
import Img from "gatsby-image"
import logoICabbi from "../images/logo_icabbi.png";
import logoAutocab from "../images/logo_autocab.png";
import logoCabguru from "../images/logo_cabguru.webp";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { FaYoutubeSquare } from "react-icons/fa";

const IndexPage = ({ data }) => {
  const deviceImage = data.deviceImage.childImageSharp.fixed
  const portalImage = data.portalImage.childImageSharp.fluid
  const ogImage = data.ogImage.childImageSharp.fixed
  const location = useLocation()
  return (
    <Layout>
      <SEO title="Home" image={ogImage} />
      <Hero image={data.heroImage.childImageSharp.fluid}>
        <div className="columns is-mobile is-gapless fullheight">
          <div className="column is-12-mobile hero-text-container">
            <div className="columns is-mobile is-gapless">

              <div className="column is-4 has-text-left">
                <img src={logoWhite} alt="Taxibooker logo" />
              </div>
              <div className="column is-8 is-invisible-tablet">
                <div className="has-text-right">
                  <a className="has-text-white has-text-weight-bold" href="https://admin.taxi-booker.com/" target="_blank" rel="noopener noreferrer">
                    <span>PORTAL LOGIN</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="hero-text has-text-weight-bold">
              Automate and capture bookings from bars, restaurants, hotels, reception desks
            </div>
            <div>
              <ScrollLink className="button is-size-5 primary has-text-weight-bold" to="form" smooth={true} offset={50} duration={1000} >REQUEST A DEMO</ScrollLink>
            </div>
          </div>

          <div className="column is-6 is-hidden-mobile hero-device-container">
            <div className="has-text-right">
              <a className="has-text-white has-text-weight-bold" href="https://admin.taxi-booker.com/" target="_blank" rel="noopener noreferrer">
                <span>PORTAL LOGIN</span>
              </a>
            </div>

            <div className="hero-device fullheight">
              <Img fixed={deviceImage} alt="Taxibooker app installed on a device" />

            </div>
          </div>
        </div>
      </Hero>


      <section className="bg-light">
        <div className="dispatchers container">
          <div className="columns is-gapless">
            <div className="column is-3 has-text-centered-mobile my-3 is-flex is-align-items-center is-justify-content-center">
              <span className="is-size-4">Works with</span>
            </div>
            <div className="column is-3 has-text-centered my-3 is-flex is-align-items-center is-justify-content-center">
              <a href="https://www.icabbi.com/marketplace/partners/taxi-booker/" target="_blank" rel="noreferrer">
                <img src={logoICabbi} width="50%" alt="iCabbi dispatcher logo" />
              </a>
            </div>
            <div className="column is-3 has-text-right has-text-centered-mobile my-3 is-flex is-align-items-center is-justify-content-center" >
              <a href="https://www.connectautocab.com/booking-stations" target="_blank" rel="noreferrer">
                <img src={logoAutocab} width="50%" alt="Autocab dispatcher logo" />
              </a>
            </div>

            <div className="column is-3 has-text-right has-text-centered-mobile my-3 is-flex is-align-items-center is-justify-content-center" >
              <div className="flex flex-row">
                <a href="https://cabguru.com/" target="_blank" rel="noreferrer">
                  <img src={logoCabguru} width="50%" height="100" alt="Cabguru dispatcher logo" />
                </a>
              </div>
            </div>
          </div>
        </div>


      </section>
      <section className="container section">
        <div className="section-title has-text-centered">
          <span className="is-size-3">Key benefits</span>
        </div>
        <div className="columns columns-reverse is-gapless is-centered">
          <div className="column is-4" >
            <div className="product-description">

              <p>Works with <strong> any Android device </strong> (4.1+)</p>
              <p>Cost efficient</p>
              <p>Easily managed via the web portal</p>
              <p>Sound notification on driver's arrival</p>
            </div>
          </div>
          <div className="column is-4 has-text-centered">

            <Img fixed={deviceImage} alt="Taxibooker app installed on a device" />
          </div>
          <div className="column is-4 has-text-justified-mobile">
            <div className="product-description">
              <p>Fully customizable with your <strong>logo & brand colours</strong></p>
              <p>WIFI & SIM</p>
              <p>Configurable <strong>booking options</strong> (prebooking, destination, vehicle type, account bookings, passenger details)</p>
              <p>Multi-language</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container section">
        <div className="section-title has-text-centered">
          <span className="is-size-3">How it works</span>
        </div>
        <div className="columns is-gapless is-centered">

          <div className="column is-6 has-text-justified-mobile">
            <div className="how-it-works">
              <div className="timeline">
                <ul>
                  <li>
                    <div className="content">
                      <h3>Tap on <strong>book</strong></h3>
                    </div>
                  </li>

                  <li>
                    <div className="content">
                      <h3>Booking <strong>confirmed</strong></h3>
                    </div>
                  </li>

                  <li>
                    <div className="content">
                      <h3>Vehicle information & <strong>ETA</strong></h3>
                    </div>
                  </li>

                  <li>
                    <div className="content">
                      <h3>Your taxi <strong>has arrived</strong></h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-6 has-text-centered-mobile has-text-right">

            <Img fixed={deviceImage} alt="Taxibooker app installed on a device" />
            {/* <svg width="251" height="433" viewBox="0 0 251 433" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f)">
              <path d="M125.574 409.559C182.002 409.554 227.745 404.596 227.744 398.487C227.743 392.377 181.999 387.429 125.572 387.435C69.1447 387.441 23.4017 392.398 23.4023 398.508C23.403 404.617 69.147 409.565 125.574 409.559Z" fill="black" fillOpacity="0.48627" />
            </g>
            <path d="M124.416 0.010848C99.4837 0.0455343 74.5756 0.981975 49.6451 3.04113C49.6451 3.04113 25.0301 4.32343 23.5506 19.0708L23.5882 381.979C24.1797 397.872 37.3937 403.775 82.1663 404.824C111.781 405.518 139.592 405.461 168.505 404.815C213.28 403.816 226.584 397.852 227.172 381.957L227.134 19.0497C226.045 3.33183 201.037 3.02544 201.037 3.02544C176.104 0.97154 151.107 0.00808197 126.173 0.010666L124.416 0.010848ZM31.3768 33.8391L218.669 33.8197L218.704 365.985L31.4112 366.004L31.3768 33.8391Z" fill="#161616" />
            <path d="M56.4554 21.3874C57.2712 21.3873 57.9325 20.7078 57.9324 19.8696C57.9323 19.0315 57.2709 18.3521 56.4551 18.3522C55.6392 18.3523 54.978 19.0318 54.978 19.87C54.9781 20.7081 55.6396 21.3875 56.4554 21.3874Z" fill="white" fillOpacity="0.18846" />
            <path d="M125.511 22.798C127.841 22.7978 129.73 20.9133 129.73 18.5888C129.73 16.2643 127.84 14.3802 125.51 14.3804C123.18 14.3807 121.291 16.2652 121.291 18.5897C121.292 20.9141 123.181 22.7983 125.511 22.798Z" fill="white" fillOpacity="0.18846" />
            <path d="M228.555 50.6014L226.6 50.6016L226.603 77.0589L228.558 77.0587L228.555 50.6014Z" fill="#161616" />
            <path d="M24.3669 75.8254L22.0079 75.8256L22.0145 139.88L24.3736 139.88L24.3669 75.8254Z" fill="#161616" />
            <path fillRule="evenodd" clipRule="evenodd" d="M218.577 365.999L160.148 365.456L113.296 33.8272L218.577 33.8272V365.999Z" fill="white" fillOpacity="0.145" />
            <g transform="translate(30,-71) scale(1,1)">
              <foreignObject x="-150" y="0" width="339" height="760">
                <video width="188" controls className="device-video" autoPlay loop muted playsInline poster={device}>
                  <source src={deviceVideo} type="video/mp4" />
                </video>
              </foreignObject>
            </g>

            <defs>
              <filter id="filter0_f" x="0.71386" y="364.737" width="249.719" height="67.5203" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="11.3437" result="effect1_foregroundBlur" />
              </filter>
            </defs>
          </svg> */}

          </div>
        </div>
      </section>

      <section className="container section">
        <div className="section-title has-text-centered">
          <span className="is-size-3">Web portal</span>
        </div>

        <div className="section-portal">
          <Img fluid={portalImage} style={{ width: "100%" }} alt="Web portal" />
          <div>
            <div className="description">
              <p>Set up alerts when devices go offline with <strong>monitoring</strong></p>
              <p>Manage your taxi bookers easily</p>
              <p>Understand your booking data with <strong>insights</strong></p>
              <p>CSV booking exports & export API available</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container section">
        <div className="section-title has-text-centered">
          <span className="is-size-3">Taxibooker videos</span>
        </div>
        <div className="columns is-centered is-vcentered">
          <div className="column is-5 is-12-mobile">
            <iframe title="yt-1" width="100%" height="300" src="https://www.youtube.com/embed/IHNpK8-EIfc" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
          <div className="column is-5 is-12-mobile">
            <iframe title="yt-2" width="100%" height="300" src="https://www.youtube.com/embed/348BkdlNXWU" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
          </div>
          <div className="column is-2 is-12-mobile is-flex is-align-items-center is-justify-content-center">
            <a className="button primary is-align-self-center" href="https://www.youtube.com/channel/UCyFYHhDsuFM8fvEtaH_2vkQ/featured" target="_blank" arget="_blank" rel="noopener noreferrer">
              <FaYoutubeSquare style={{ fontSize: "1.5rem" }} />
              <span>
                &nbsp; VIEW ALL
              </span>
            </a>
          </div>
        </div>
      </section>

      <section className="container section">
        <div className="section-title has-text-centered">
          <span className="is-size-3">Get in touch</span>
        </div>

        <div className="columns is-gapless is-centered">
          <div className="column is-6-tablet is-12-mobile">
            <Element name="form">
              <form name="Contact Form" action="/form-success" method="post" netlify-honeypot="bot-field" data-netlify="true" className="columns is-multiline container text-dark-grey">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Contact Form" />


                <input required className="input" type="text" placeholder="Company Name" name="companyName" ></input>
                <input required className="input mt-4" type="text" placeholder="Name" name="name" ></input>
                <input required className="input mt-4" type="tel" placeholder="Phone" name="phone" ></input>
                <input className="input mt-4 mb-4" type="email" placeholder="Email" name="email"></input>

                <div className="field w-100">
                  <label htmlFor="dispatcher" className="label">Dispatcher type</label>
                  <div className="select w-100">
                    <select name="dispatcher" className="w-100">
                      <option value="autocab">Autocab</option>
                      <option value="icabbi">iCabbi</option>
                      <option value="cabguru">Cab Guru/Cordic</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <button type="submit" className="mt-4 button is-size-5 primary has-text-weight-bold is-fullwidth">REQUEST DEMO</button>
              </form>
            </Element>
          </div>
        </div>
      </section>


      <section className="container section">
        <div className="section-title has-text-centered">
          <span className="is-size-3">Follow us</span>
        </div>
        <div className="columns is-centered">
          <div className="column is-6 is-12-mobile is-flex">
            <iframe title="fb" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftaxibooker&tabs=timeline&width=360&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="360" height="500" style={{ border: "none", overflow: "hidden", margin: "0px auto" }} scrolling="no" frameBorder={0} allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
        </div>
      </section>

      <CookieConsent
        location="bottom"
        buttonText="I understand"
        enableDeclineButton
        flipButtons
        declineButtonText="I decline"
        cookieName="cookies-accepted"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "24px" }}
        declineButtonStyle={{ fontSize: "24px" }}
        expires={150}
        onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', true)
          Cookies.set('gatsby-gdpr-google-tagmanager', true)
          Cookies.set('gatsby-gdpr-facebook-pixel', true)
          initializeAndTrack(location)
        }}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </Layout>
  )
}


export const query = graphql`
query {
  heroImage: file(relativePath: { eq: "hero_home.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  },
  ogImage: file(relativePath: { eq: "og_image.png" }) {
    childImageSharp {
      fixed {
        ...GatsbyImageSharpFixed
      }
    }
  },
  portalImage: file(relativePath: { eq: "portal.png" }) {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  deviceImage: file(relativePath: { eq: "device.png" }) {
    childImageSharp {
      fixed(height: 500) {
        ...GatsbyImageSharpFixed
      }
    }
  },
}
`

export default IndexPage
